import { useTheme } from '@material-ui/core';
import React, { PureComponent, useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import Title from './Title';


import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import Grid from "@material-ui/core/Grid";


const BarChartDash = () => {

  const theme = useTheme();

  let [chartData, setChartData] = useState([]);

  let initialDate = new Date();
  initialDate.setMonth(initialDate.getMonth() - 1);
  initialDate.setHours(0, 0, 0, 0);

  let finalDate = new Date();

  let [dateValue, setDateValue] = React.useState([initialDate, finalDate]);


  function setDateValueAndFetchData(value) {
    setDateValue(value);
    fetchData();
  }

  async function fetchData() {
    let getChartData = await api.post("/dashboard", { initialDate: dateValue[0], finalDate: dateValue[1] });
    if (getChartData.status === 200) {
      setChartData(getChartData.data);
    } else {
      toastError(getChartData.data.error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>

      <React.Fragment>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <Grid container justify="space-between">
            <Grid item>
              <Title>
                {`${i18n.t("dashboard.charts.perAnalist.title")}`}
              </Title>
            </Grid>
            <Grid item>
              <DateRangePicker
                startText={i18n.t("dashboard.charts.perAnalist.initialDate")}
                endText={i18n.t("dashboard.charts.perAnalist.finalDate")}
                inputFormat="dd/MM/yyyy"
                value={dateValue}
                onChange={(newValue) => {
                  setDateValueAndFetchData(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} style={{ marginBottom: "5px" }}/>
                    <Box sx={{ mx: 1 }}></Box>
                    <TextField {...endProps} style={{ marginRight: "30px" }}/>
                  </React.Fragment>
                )}
                style={{ display: 'inline-block' }}
              />
            </Grid>
          </Grid>

        </LocalizationProvider>
      </React.Fragment>



      <ResponsiveContainer height={300}>


        <BarChart
          data={chartData}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="user" />
          <YAxis
            type="number"
            allowDecimals={false}
            stroke={theme.palette.text.secondary}
          >
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
            >
              Tickets
            </Label>
          </YAxis>
          <Tooltip cursor={false} />
          {/* <Legend /> */}
          <Bar dataKey="tickets" name="Tickets" fill={theme.palette.primary.main} />
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  )

}

export default BarChartDash;